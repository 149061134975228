<template>
  <div class="course">
    <t_header :isUserInfo="isUserInfo" @setTodos="getTodos"></t_header>
    <div class="coursediv">
      <div class="coursediv_condition">
        <p class="coursediv_condition_title"></p>
        <div class="coursediv_condition_name">
          <p :class="oneTypeId==item.id?'selectp':''" v-for="(item,index) in courseCategoryFirstList" :key="index" @click="getOneType(item)">{{item.name}}</p>
        </div>
      </div>
      <!-- <div class="coursediv_condition">
            <p class="coursediv_condition_title"></p>
            <div class="coursediv_condition_name">
                <p :class="twoTypeId==val.id?'selectp':''" v-for="(val,key) in courseCategorySecondList" @click="getTwoType(val)" :key="key">{{val.name}}</p>
            </div>
        </div> -->
      <div class="coursediv_condition">
        <p class="coursediv_condition_title"></p>
        <div class="coursediv_condition_name">
          <p :class="threeTypeId==val.id?'selectp':''" v-for="(val,key) in threeTypeList" @click="getThreeType(val)" :key="key">{{val.name}}</p>
        </div>
      </div>
      <div class="coursediv_calss">
        <div @click="todetail(item)" v-for="(item,index) in courseList" :key="index">
          <img :src="item.coverPath">
          <p class="coursediv_calss_title">{{item.courseName}}</p>
          <p v-if="item.isFree != 0">

            <span class="color_red" v-if="item.isDiscount==1">限时折扣：¥{{item.discountPrice}}</span>
            <span :class="item.isDiscount==1?'color_ccc':'color_red'">¥{{item.price}}</span>
          </p>
          <p v-if="item.isFree == 0">
            <span class="color_blue">免费</span>
          </p>
        </div>
      </div>
      <div class="coursediv_empty" v-if="!courseList.length">
        <div class="coursediv_empty_img">
          <img src="@/assets/class_empty.png" alt="">
        </div>
        <div class="coursediv_empty_text">无相关内容<br>快去学习吧</div>
      </div>
      <div class="coursediv_pagination" v-if="courseCount">
        <el-pagination background @current-change="handleCurrentChange" :page-size="20" layout="total, prev, pager, next" :total="courseCount">
        </el-pagination>
      </div>
    </div>

    <t_footer></t_footer>
  </div>

</template>

<script>
import t_header from '@/components/t_header/index.vue'
import t_footer from '@/components/t_footer/index.vue'
import { first_types, second_types, course_list } from "@/api/course";

export default {
  name: 'course',
  components: {
    t_header,
    t_footer
  },
  data() {
    return {
      isType: '1',
      isSort: false,
      courseCategoryFirstList: [],
      courseCategorySecondList: [],
      oneTypeId: '',
      twoTypeId: '',
      page: 0,
      courseList: [],
      courseCount: 0,
      routerInfo: {},// 跳转过来的数据
      threeTypeId: 0,
      threeTypeList: [
        {
          id: 0,
          name: '全部',
        },
        {
          id: 1,
          name: '付费',
        },
        {
          id: 2,
          name: '折扣',
        },
        {
          id: 3,
          name: '免费',
        },
      ],
      isUserInfo: false
    }
  },
  watch: {
    $route(to, from) {
      window.scrollTo(0, 0);
      this.getCourseList()
    },
  },
  mounted() {
    // if (JSON.stringify(this.$route.query) != '{}' && this.$route.query != null && this.$route.query != undefined) {
    //   this.routerInfo = JSON.parse(this.$route.query.item);
    //   this.oneTypeId = JSON.parse(this.$route.query.item).id;
    // }
    this.getCourseFirstTypes()
  },
  methods: {
    // 父传子
    getTodos(e) {
      this.isUserInfo = e
    },
    // 去详情
    todetail(item) {
      let routeUrl = this.$router.resolve({
        path: '/coursedetail',
        query: { coursePackageId: item.coursePackageId }
      });
      window.open(routeUrl.href, '_blank');
    },
    // 课程所有一级分类
    getCourseFirstTypes() {
      first_types().then(res => {
          if (res.data.code == 0) {
            let courseCategoryFirstList = res.data.data;
            let params = {
              name: '全部',
              "id": "",
            };
            courseCategoryFirstList.unshift(params);
            let queryName = this.$route.query && this.$route.query.enName;
            let oneTypeId = '';
            courseCategoryFirstList.forEach(item => {
                if(item.enName == queryName && queryName){
                    oneTypeId = item.id;
                }
            });
            this.oneTypeId = oneTypeId;
            this.courseCategoryFirstList = courseCategoryFirstList;
            this.getCourseSecondTypes(oneTypeId);
          }
          else {
            this.$message.error(res.data.msg);
          }
        })
    },
    // 课程二级分类
    getCourseSecondTypes(firstTypeIds) {
      second_types({ firstTypeId: firstTypeIds }).then(res => {
        if (res.data.code == 0) {
          let courseCategorySecondList = res.data.data;
          let params = {
            name: '全部',
            "id": "",
          };
          courseCategorySecondList.unshift(params);
          this.courseCategorySecondList = courseCategorySecondList;
          this.getCourseList();
        }
        else {
          this.$message.error(res.data.msg);
        }
      })
    },
    // 点击一级类别
    getOneType(item) {
      this.oneTypeId = item.id;
      this.twoTypeId = '';
      this.getCourseSecondTypes(item.id);
      this.page = 0;
      this.courseList = [];
    },
    // 点击二级类别
    getTwoType(val) {
      this.twoTypeId = val.id;
      this.page = 0
      this.courseList = []
      this.getCourseList()
    },
    // 点击二级类别
    getThreeType(val) {
      this.threeTypeId = val.id;
      this.page = 0
      this.courseList = []
      this.getCourseList()
    },
    // 课程列表
    getCourseList() {
      let params = {
        page: this.page,
        firstTypeId: this.oneTypeId,
        secondTypeId: this.twoTypeId,
        page: this.page,
        type: this.threeTypeId,
        pageSize: 20
      }
      course_list(params).then(res => {
        if (res.data.code == 0) {
          this.courseList = res.data.data
          this.courseCount = Number(res.data.count)
        }
        else {
          this.$message.error(res.data.msg);
        }
      })
    },

    // 分页
    handleCurrentChange(val) {
      this.page = val - 1
      this.getCourseList()
    },
  }
}
</script>
