import $axios from './index'

//课程所有一级分类
export function first_types(data){
	const url = '/api/app/v1/course/type/first/types';
	return $axios.post(url,data);
}
//获取有直播的课程分类列表
export function courseTypeList(data){
	const url = '/api/pc/v1/live/courseTypeList';
	return $axios.post(url,data);
}


//课程二级分类
export function second_types(data){
	const url = '/api/app/v1/course/type/second/types';
	return $axios.post(url,data);
}
//课程套餐列表
export function course_list(data){
	const url = '/api/app/v1/course/package/list';
	return $axios.post(url,data);
}
//课程套餐详情（PC和APP通用接口）
export function package_detail(data){
	const url = '/api/app/v1/course/package/detail';
	return $axios.post(url,data);
}
//生成套餐订单信息
export function get_order(data){
	const url = '/api/app/v1/course/order/get/order';
	return $axios.post(url,data);
}
//立即支付
export function course_pay(data){
	const url = '/api/pc/v1/pay/course/package/pay';
	return $axios.post(url,data);
}
//获取订单状态
export function order_status(data){
	const url = '/api/pc/v1/pay/get/order/status';
	return $axios.post(url,data);
}
//领取课程优惠券
export function course_getCoupon(data){
	const url = '/api/app/v1/course/package/receive/course/coupon';
	return $axios.post(url,data);
}
//推荐套餐列表
export function course_recommendList(data){
	const url = '/api/pc/v1/course/package/recommend/list';
	return $axios.post(url,data);
}


// 直播（套餐中）
//直播课程:课程列表
export function live_courseList(data){
	const url = '/api/app/v1/course/package/live/course/list';
	return $axios.post(url,data);
}

//直播课程:课程分类列表
export function live_classify(data){
	const url = '/api/app/v1/course/package/live/course/type/list';
	return $axios.post(url,data);
}

//直播课程:套餐列表
export function coursepackage_list(data){
	const url = '/api/app/v1/course/package/live/coursepackage/list';
	return $axios.post(url,data);
}

//直播课程：直播列表
export function courseLive_list(data){
	const url = '/api/app/v1/course/package/live/list';
	return $axios.post(url,data);
}


// 直播
//直播列表
export function pclive_list(data){
	const url = '/api/pc/v1/live/list';
	return $axios.post(url,data);
}
//时间轴数据
export function time_line(data){
	const url = '/api/pc/v1/live/time/line/data';
	return $axios.post(url,data);
}

//今日免费直播中列表
export function todayFree_liveList(data){
	const url = '/api/pc/v1/live/today/free/live/list';
	return $axios.post(url,data);
}

//预约
export function live_reserve(data){
	const url = '/api/app/v1/live/reserve';
	return $axios.post(url,data);
}
//支付直播订单
export function live_orderPay(data){
	const url = '/api/pc/v1/pay/live/pay';
	return $axios.post(url,data);
}
//生成直播订单
export function gen_liveOrder(data){
	const url = '/api/app/v1/live/gen/order';
	return $axios.post(url,data);
}
//根据直播频道号获取回放视频
export function live_playback(data){
	const url = '/api/app/v1/live/playback';
	return $axios.post(url,data);
}
//根据类型获取直播信息
export function getliveModeByChannelAndType(data){
	const url = '/api/app/v1/live/getliveModeByChannelAndType';
	return $axios.post(url,data);
}
//根据直播频道号获取回放视频
export function playbackByChannelId(data){
	const url = '/api/app/v1/live/playbackByChannelId';
	return $axios.post(url,data);
}
//根据直播频道号获取回放视频
export function free_connect(data){
	const url = '/api/app/v1/course/study/coursepackage/course/list';
	return $axios.post(url,data);
}

